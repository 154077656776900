import { Layout } from '../../components/Layout';
import { Title } from '../../components/Order/subs/Title';
import questionSvg from '../../assets/svgs/question.svg';

import style from './style.module.css';

export function NotFound() {
  return (
    <Layout isLoading={false}>
      <div className={style.notFoundWrapper}>
        <div className={style.missingOrder}>
          <img alt="box" style={{ height: 40 }} src={questionSvg} />

          <Title>{'Tyvärr hittar vi inte din order'}</Title>

          <p>
            {
              'Spårning kan saknas eller så har fel order- eller leveransnummer fyllts i.'
            }
          </p>

          <p>
            {
              'Vänligen kontakta kundsupport 010-161 86 00 om du behöver få mer information om din order.'
            }
          </p>
        </div>
      </div>
    </Layout>
  );
}
