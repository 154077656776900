import dayjs from 'dayjs';
import { getOrderStatus, ConsolidationTransportOrderStatus, CustomerContactType, AllNotificationFlags, formatDateWithTimeSpan, formatDate, ROUTE_MAP, getAllNoneCancelledOrderLines, ShipViaCodes, getTimeStampDeliveryType, formatDateWithTimeSpanForNonTimestampDelivery, } from './index';
export const isOrderNotification = (notification) => {
    return notification.type === NotificationType.ORDER;
};
export const isShipmentNotification = (notification) => {
    return notification.type === NotificationType.SHIPMENT;
};
export var NotificationType;
(function (NotificationType) {
    NotificationType[NotificationType["ORDER"] = 0] = "ORDER";
    NotificationType[NotificationType["SHIPMENT"] = 1] = "SHIPMENT";
})(NotificationType || (NotificationType = {}));
export var TriggerType;
(function (TriggerType) {
    TriggerType["ORDER"] = "ORDER";
    TriggerType["CONSOLIDATION"] = "CONSOLIDATION";
})(TriggerType || (TriggerType = {}));
export var Branch;
(function (Branch) {
    Branch["Dbi"] = "300";
    Branch["Timber"] = "200";
    Branch["Trateknik"] = "500";
})(Branch || (Branch = {}));
export const getUniqueOrderNos = (notification) => {
    return Array.from(new Set(notification.orderNotifications.map((order) => order.ORDER_NO)));
};
export const getHighestPriorityRoute = (notification) => {
    // Get all mapped routes in priority order
    const routes = [
        ...notification.orderNotifications
            .filter((n) => n.ROUTE_ID !== undefined)
            .map((n) => (n.ROUTE_ID ? ROUTE_MAP[n.ROUTE_ID] : undefined)),
        notification.ROUTE_ID ? ROUTE_MAP[notification.ROUTE_ID] : undefined,
    ]
        .filter(Boolean)
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore - Array.filter returns an array of non-nullable values
        .sort((a, b) => a.priority - b.priority);
    return routes.length > 0 ? routes[0] : undefined;
};
export const getDeliveryDateTime = (notification, today = new Date()) => {
    const route = getHighestPriorityRoute(notification);
    const plannedDate = notification.PLANNED_DELIVERY_DATE;
    let deliveryDateTime = '';
    if (!route) {
        if (notification.STATUS === ConsolidationTransportOrderStatus.DRIVE_TO) {
            deliveryDateTime = formatDateWithTimeSpan(plannedDate, 10, 10, 'minutes', today);
        }
        else {
            deliveryDateTime = formatDateWithTimeSpanForNonTimestampDelivery(plannedDate, 1, 3, 'hours', today);
        }
    }
    else {
        const timeStampInfo = getTimeStampDeliveryType(route.ROUTE_ID);
        deliveryDateTime = `${formatDate(notification.PLANNED_DELIVERY_DATE)} kl ${route.display}`;
        if (timeStampInfo.isTimeStampDelivery) {
            if (notification.STATUS === ConsolidationTransportOrderStatus.DRIVE_TO) {
                deliveryDateTime = formatDateWithTimeSpan(plannedDate, 10, 10, 'minutes', today);
            }
        }
        else {
            if (notification.STATUS === ConsolidationTransportOrderStatus.DRIVE_TO) {
                deliveryDateTime = formatDateWithTimeSpan(plannedDate, 15, 45, 'minutes', today);
            }
            else {
                const plannedDeliveryTime = dayjs(notification.PLANNED_DELIVERY_DATE);
                switch (timeStampInfo.stamp) {
                    case 'MORG':
                        deliveryDateTime = `${formatDate(plannedDate)} kl 06:30-09:00`;
                        break;
                    case 'FM':
                        deliveryDateTime = `${formatDate(plannedDate)} kl 07:30-12:00`;
                        break;
                    case 'EM':
                        deliveryDateTime = `${formatDate(plannedDate)} kl 12:00-16:30`;
                        break;
                    case 'VALFRI':
                        if (plannedDeliveryTime.get('hour') == 17 &&
                            plannedDeliveryTime.get('minute') == 0) {
                            deliveryDateTime = formatDateWithTimeSpanForNonTimestampDelivery(plannedDeliveryTime.toISOString(), 1.5, 0, 'hours', today);
                        }
                        else {
                            deliveryDateTime = formatDateWithTimeSpanForNonTimestampDelivery(notification.PLANNED_DELIVERY_DATE, 1, 3, 'hours', today);
                        }
                        break;
                    default:
                        deliveryDateTime = formatDateWithTimeSpanForNonTimestampDelivery(notification.PLANNED_DELIVERY_DATE, 1, 3, 'hours', today);
                }
            }
        }
    }
    if (notification.COMPANY && notification.COMPANY === Branch.Trateknik) {
        const timeIndex = deliveryDateTime.indexOf('kl');
        if (timeIndex !== -1) {
            deliveryDateTime = deliveryDateTime.substring(0, timeIndex).trim();
        }
    }
    return deliveryDateTime;
};
// We create a GSI so that we can track any previously sent notifications
export const notificationSecondaryPartitionKey = (notification) => {
    const orderNos = notification.ORDER_NOS.sort().join('#');
    return `${orderNos}#${notification.NOTIFICATION_TYPE}#${notification.CUSTOMER_CONTACT.ID}`;
};
// We let the sort key be the createdAt date so we easliy can fetch the latest notifications
export const notificationSecondarySortKey = (notification) => {
    return notification.createdAt;
};
export const orderNotificationFromOrder = (order, customerContact, shipmentLines, routeId, supplySite, sourceOrder, transportOrderNos, previousOrder) => {
    // Default is all flags
    customerContact.notificationSettings =
        customerContact?.notificationSettings ?? AllNotificationFlags;
    const custRef = order.DELIVERY_INFO.CUSTOMER_CONTACT.find((c) => c.CONTACT_TYPE === CustomerContactType.CUST_REF);
    const custReceiver = order.DELIVERY_INFO.CUSTOMER_CONTACT.find((c) => c.CONTACT_TYPE === CustomerContactType.RECEIVER);
    // If this is a pickup order we use either the supply site
    // or the pickup address as the pickup address
    const PICKUP_ADDRESS = [ShipViaCodes.HTI, ShipViaCodes.HMT].includes(order.SHIP_VIA_CODE)
        ? supplySite ?? order.SUPPLIER_ADDRESS
        : undefined;
    // We fall back on source order in this case
    const orderNo = order.sourceOrderNo && order.sourceOrderNo !== ''
        ? order.sourceOrderNo
        : order.ORDER_NO;
    const notification = {
        type: NotificationType.ORDER,
        createdAt: new Date().toJSON(),
        COMPANY: order.COMPANY,
        CUSTOMER_ID: order.CUSTOMER_ID,
        CUSTOMER_NAME: order.CUSTOMER_NAME,
        STATUS: getOrderStatus(order),
        PLANNED_DELIVERY_DATE: order.PLANNED_DELIVERY_DATE,
        MARKING: sourceOrder ? sourceOrder.MARKING : order.MARKING,
        CUSTOMER_PO_NO: order.CUSTOMER_PO_NO,
        DELIVER_ADDRESS: order.DELIVER_ADDRESS,
        CUSTOMER_CONTACT: customerContact,
        CUST_REF: custRef?.NAME,
        CUSTOMER_REF: custRef,
        CUSTOMER_RECEIVER: custReceiver,
        ORDER_NO: orderNo,
        ORDER_NOS: [orderNo],
        TRANSPORT_ORDER_NOS: transportOrderNos || [],
        ORDER_LINES: getAllNoneCancelledOrderLines(order),
        NOTIFICATION_TYPE: customerContact.NOTIFICATION_TYPE,
        TRANSPORT_LINES: shipmentLines,
        ROUTE_ID: routeId,
        SHIP_VIA_CODE: order.SHIP_VIA_CODE,
        PICKUP_ADDRESS,
        NOTIFICATION_META: getNotificationMeta(order, previousOrder),
    };
    return notification;
};
export var NotificationMeta;
(function (NotificationMeta) {
    NotificationMeta["UPDATED_PLANNED_DELIVERY_DATE"] = "PLANNED_DELIVERY_UPDATE";
    NotificationMeta["UPDATED_ADDRESS"] = "UPDATED_ADDRESS";
    NotificationMeta["UPDATED"] = "UPDATED";
})(NotificationMeta || (NotificationMeta = {}));
const getNotificationMeta = (order, previous) => {
    const fns = [
        (n, o) => {
            if (!o?.DELIVER_ADDRESS.ADDRESS_1) {
                return null;
            }
            else {
                return n.DELIVER_ADDRESS.ADDRESS_1 != o?.DELIVER_ADDRESS.ADDRESS_1
                    ? NotificationMeta.UPDATED_ADDRESS
                    : null;
            }
        },
        (n, o) => {
            if (!o?.PLANNED_DELIVERY_DATE) {
                return null;
            }
            else {
                return n.PLANNED_DELIVERY_DATE != o?.PLANNED_DELIVERY_DATE
                    ? NotificationMeta.UPDATED_PLANNED_DELIVERY_DATE
                    : null;
            }
        },
    ];
    return fns
        .map((fn) => fn(order, previous))
        .filter((n) => !!n);
};
/**
 * Returns true/false depending on it's PLANNED_DELIVERY_DATE.
 *
 * timestamp (06:00, 06:15, ...) deliveries can be created and sent without restriction.
 *
 * However, non-timestamp deliveries like MORG, EM, etc., should never be sent
 * if they fall outside the daily time boundaries. (06:30 - 17:00).
 */
export const isNotifcationWithinTimespanRestriction = (d) => {
    if (!d.ROUTE_ID)
        throw new Error('Notification does not have a ROUTE_ID');
    const route = getTimeStampDeliveryType(d.ROUTE_ID);
    if (route.isTimeStampDelivery)
        return true;
    let origin = dayjs(d.PLANNED_DELIVERY_DATE);
    const lowerBound = origin.set('hour', 6).set('minute', 29);
    const upperBound = origin.set('hour', 17).set('minute', 1);
    if (d.PLANNED_DELIVERY_DATE.length == 10) {
        // str date without time is always OK
        origin = origin.set('hour', 13).set('minute', 37);
    }
    return lowerBound < origin && origin < upperBound;
};
