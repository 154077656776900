import styles from './styles.module.css';

export interface HeaderProps {
  header: string;
}

export const Header = ({
  header
}: HeaderProps) => {
  return (
    <h3 className={styles.componentHeader}>
      {header}
    </h3>
  )
};

export const PageHeader = ({
  header
}: HeaderProps) => {
  return (
    <h1 className={styles.componentPageHeader}>
      {header}
    </h1>
  )
};

export const SubHeader = ({
  header
}: HeaderProps) => {
  return (
    <h4 className={styles.componentSubHeader}>
      {header}
    </h4>
  )
};
