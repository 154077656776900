import '@deromeab/components/dist/esm/index.css';

import { GenericInfo } from '@deromeab/components';

interface Props {
  marking: string;
  orderNo: string;
  shipViaCode?: string;
  shipmentId?: string;
  pickup?: {
    city?: string;
    name?: string;
    address?: string;
    zipCode?: string;
  };
}

export const CustomerInfo = ({ marking, orderNo }: Props) => {
  const customerInfo = [];

  if (marking) {
    customerInfo.push([
      {
        title: 'Projekt/Märkning',
        items: [marking],
      },
    ]);
  }

  customerInfo.push([
    {
      title: 'Ordernr',
      items: [orderNo],
    },
  ]);

  if (!customerInfo.length) {
    return <></>;
  }

  return <GenericInfo data={customerInfo} />;
};
