export var ShipmentStatus;
(function (ShipmentStatus) {
    ShipmentStatus["CREATED"] = "CREATED";
    ShipmentStatus["PICKED"] = "PICKED";
    ShipmentStatus["DELIVERY_PLANNED"] = "DELIVERY_PLANNED";
    ShipmentStatus["PLANNED"] = "PLANNED";
    ShipmentStatus["DRIVE_TO"] = "DRIVE_TO";
    ShipmentStatus["DELIVERED"] = "DELIVERED";
    ShipmentStatus["READY"] = "READY";
})(ShipmentStatus || (ShipmentStatus = {}));
/**
 * Determines if the provided route ID is valid and translates it to a constant.
 * Furthermore this function helps you to determine if the timestamps is a  `TimestampDelivery`
 * or `NontimeStampDelivery`. Different notification rules / messages apply for respective
 * variant.
 */
export const getTimeStampDeliveryType = (routeId) => {
    const i = TIME_OF_DAY_DELIVERY_LIST.findIndex((v) => v === routeId);
    if (i != -1) {
        return {
            isTimeStampDelivery: true,
            stamp: TIME_OF_DAY_DELIVERY_LIST[i],
        };
    }
    const j = NON_TIME_OF_DAY_DELIVERY_LIST.findIndex((v) => v === routeId);
    if (j != -1) {
        return {
            isTimeStampDelivery: false,
            stamp: NON_TIME_OF_DAY_DELIVERY_LIST[j],
        };
    }
    return {
        isTimeStampDelivery: false,
        stamp: 'VALFRI',
    };
};
// A.k.a klockslagsleveranser
export const TIME_OF_DAY_DELIVERY_LIST = [
    '06:00',
    '06:15',
    '06:30',
    '06:45',
    '07:00',
    '07:15',
    '07:30',
    '07:45',
    '08:00',
    '08:15',
    '08:30',
    '08:45',
    '09:00',
    '09:15',
    '09:30',
    '09:45',
    '10:00',
    '10:15',
    '10:30',
    '10:45',
    '11:00',
    '11:15',
    '11:30',
    '11:45',
    '12:00',
    '12:15',
    '12:30',
    '12:45',
    '13:00',
    '13:15',
    '13:30',
    '13:45',
    '14:00',
    '14:15',
    '14:30',
    '14:45',
    '15:00',
    '15:15',
    '15:30',
    '15:45',
    '16:00',
    '16:15',
    '16:30',
    '16:45',
    '17:00',
    '17:15',
    '17:30',
    '17:45',
    '18:00',
    '18:15',
    '18:30',
    '18:45',
    '19:00',
    '19:15',
    '19:30',
    '19:45',
    '20:00',
    '20:15',
    '20:30',
    '20:45',
    '21:00',
];
// Aka icke-klockslags-leveranser
export const NON_TIME_OF_DAY_DELIVERY_LIST = [
    'MORG',
    'EM',
    'FM',
    'FR/MÅ',
    'MÅ/TI',
    'TI/ON',
    'ON/TO',
    'TO/FR',
    'VALFRI',
    'VECKA',
];
export const ROUTE_MAP = {
    ...TIME_OF_DAY_DELIVERY_LIST.reduce((times, time) => {
        times[time] = {
            ROUTE_ID: time,
            name: 'Klockslagsleverans',
            display: time,
            priority: 1,
        };
        return times;
    }, {}),
    'MORG': {
        ROUTE_ID: 'MORG',
        name: 'Morgonleverans',
        priority: 2,
        display: '06:30-09:00',
    },
    'FM': {
        ROUTE_ID: 'FM',
        name: 'Förmiddagsleverans',
        priority: 3,
        display: '07:30-12:00',
    },
    'EM': {
        ROUTE_ID: 'EM',
        name: 'Eftermiddagsleverans',
        priority: 3,
        display: '12:00-16:30',
    },
    'FR/MÅ': {
        ROUTE_ID: 'FR/MÅ',
        name: 'Leverans fredag eller måndag',
        priority: 3,
        display: undefined,
    },
    'MÅ/TI': {
        ROUTE_ID: 'MÅ/TI',
        name: 'Leverans måndag eller tisdag',
        priority: 3,
        display: undefined,
    },
    'ON/TO': {
        ROUTE_ID: 'ON/TO',
        name: 'Leverans onsdag eller torsdag',
        priority: 3,
        display: undefined,
    },
    'TI/ON': {
        ROUTE_ID: 'TI/ON',
        name: 'Leverans tisdag eller onsdag',
        priority: 3,
        display: undefined,
    },
    'TO/FR': {
        ROUTE_ID: 'TO/FR',
        name: 'Leverans torsdag eller fredag',
        priority: 3,
        display: undefined,
    },
    'VALFRI': {
        ROUTE_ID: 'VALFRI',
        name: 'Lev:s val av tid under vald dag',
        priority: 3,
        display: undefined,
    },
    'VECKA': {
        ROUTE_ID: 'VECKA',
        name: 'Lev:s val av dag under vald vecka',
        priority: 3,
        display: undefined,
    },
};
