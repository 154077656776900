import React from 'react';
import ReactDOM from 'react-dom/client';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { NotFound } from './pages/not-found';

import './styles/index.scss';

import {
  Outlet,
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from 'react-router-dom';
import NotificationAppOrderTracker from './appOrderTracker';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<Outlet />}>
      <Route path="/not-found" element={<NotFound />} />

      <Route path="/o/:orderNo" element={<NotificationAppOrderTracker />} />

      <Route path=":hash" element={<NotificationAppOrderTracker />} />

      <Route path="*" element={<NotFound />} />
    </Route>,
  ),
);
const queryClient = new QueryClient();

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />

      <ReactQueryDevtools />
    </QueryClientProvider>
  </React.StrictMode>,
);
