export var ConsolidationTransportOrderStatus;
(function (ConsolidationTransportOrderStatus) {
    ConsolidationTransportOrderStatus["CREATED"] = "CREATED";
    ConsolidationTransportOrderStatus["PLANNED"] = "PLANNED";
    ConsolidationTransportOrderStatus["PICKED"] = "PICKED";
    ConsolidationTransportOrderStatus["DRIVE_TO"] = "DRIVE_TO";
    ConsolidationTransportOrderStatus["PLANNED_ARRIVAL_TIME"] = "PLANNED_ARRIVAL_TIME";
    ConsolidationTransportOrderStatus["ARRIVED"] = "ARRIVED";
    ConsolidationTransportOrderStatus["LOADING_STARTED"] = "LOADING_STARTED";
    ConsolidationTransportOrderStatus["COMPLETED"] = "COMPLETED";
    ConsolidationTransportOrderStatus["READY_FOR_PICKUP"] = "READY_FOR_PICKUP";
})(ConsolidationTransportOrderStatus || (ConsolidationTransportOrderStatus = {}));
// Since there is no status field per transport order,
// we need to evaluate each transport order
// and determine the status.
export const getConsolidationShipmentStatus = (shipment) => {
    if (shipment.COMPLETED) {
        return ConsolidationTransportOrderStatus.COMPLETED;
    }
    if (shipment.LOADING_STARTED) {
        return ConsolidationTransportOrderStatus.LOADING_STARTED;
    }
    if (shipment.ARRIVED) {
        return ConsolidationTransportOrderStatus.ARRIVED;
    }
    if (shipment.DRIVE_TO) {
        return ConsolidationTransportOrderStatus.DRIVE_TO;
    }
    if (shipment.PLANNED_ARRIVAL_TIME) {
        return ConsolidationTransportOrderStatus.PLANNED_ARRIVAL_TIME;
    }
    return ConsolidationTransportOrderStatus.PLANNED;
};
// Return the underlying status value for a given transport order
// so that we can determine any scheduling changes
export const getValueForCurrentShipmentStatus = (shipment) => {
    const status = getConsolidationShipmentStatus(shipment);
    if (status === ConsolidationTransportOrderStatus.PLANNED) {
        return shipment.PLANNED_DELIVERY_DATE;
    }
    return shipment[status];
};
