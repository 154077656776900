import { useEffect, useState } from 'react';

import styles from './layout.module.css';
import { CloseIcon } from '../../components/Close';
import chatSvg from '../../assets/svgs/chat.svg';
import mailSvg from '../../assets/svgs/mail.svg';
import houseSvg from '../../assets/svgs/house.svg';
import earthSvg from '../../assets/svgs/earth.svg';
import { Loader } from '../Loader';

interface Props {
  children: any;
  toggleShowSettingsDialog?: () => void;
  notificationExist?: boolean;
  isLoading: boolean;
}

export const Layout = ({
  children,
  toggleShowSettingsDialog,
  notificationExist,
  isLoading,
}: Props) => {
  const [hiddenTooltipMessage, hideTooltipMessage] =
    useLocalStorage('hidden-tooltip');

  return (
    <div className={styles.layout}>
      <div className={styles.layoutHeader}>
        <DeromeLogotype />

        {notificationExist && toggleShowSettingsDialog && (
          <div className={styles.settings}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="24"
              width="24"
              onClick={toggleShowSettingsDialog}
            >
              <path d="m9.25 22-.4-3.2q-.325-.125-.612-.3-.288-.175-.563-.375L4.7 19.375l-2.75-4.75 2.575-1.95Q4.5 12.5 4.5 12.337v-.675q0-.162.025-.337L1.95 9.375l2.75-4.75 2.975 1.25q.275-.2.575-.375.3-.175.6-.3l.4-3.2h5.5l.4 3.2q.325.125.613.3.287.175.562.375l2.975-1.25 2.75 4.75-2.575 1.95q.025.175.025.337v.675q0 .163-.05.338l2.575 1.95-2.75 4.75-2.95-1.25q-.275.2-.575.375-.3.175-.6.3l-.4 3.2Zm2.8-6.5q1.45 0 2.475-1.025Q15.55 13.45 15.55 12q0-1.45-1.025-2.475Q13.5 8.5 12.05 8.5q-1.475 0-2.488 1.025Q8.55 10.55 8.55 12q0 1.45 1.012 2.475Q10.575 15.5 12.05 15.5Z" />
            </svg>

            {!hiddenTooltipMessage && (
              <div
                className={styles.tooltipSettings}
                onClick={() => hideTooltipMessage(-1)}
              >
                <p>Får du många meddelanden? Klicka här.</p>

                <CloseIcon />
              </div>
            )}
          </div>
        )}
      </div>

      {isLoading ? (
        <div className={styles.outletLoader}>
          <Loader active={isLoading} />
        </div>
      ) : (
        <>
          <div className={styles.outlet}>{children}</div>

          <footer className={styles.layoutFooter}>
            <h3>Kontakta Derome</h3>

            {footerEntries.map(({ icon, component }, i) => (
              <div key={i} className={styles.entry}>
                <img
                  alt={icon}
                  src={icon}
                  width={23}
                  style={{ marginRight: 24 }}
                />

                {component}
              </div>
            ))}

            <p style={{ marginTop: 12 }}>
              © 2022 DEROME AB, Alla rättigheter förbehållna
            </p>
          </footer>
        </>
      )}
    </div>
  );
};

const footerEntries: { icon: string; component: React.ReactElement }[] = [
  {
    icon: chatSvg,
    component: <p>Kundsupport 010-161 86 00</p>,
  },
  { icon: mailSvg, component: <p>kundsupport@derome.se</p> },
  { icon: houseSvg, component: <p>Öppet 07.00-16.00</p> },
  {
    icon: earthSvg,
    component: <a href="https://www.derome.se">derome.se</a>,
  },
];

function DeromeLogotype() {
  return (
    <svg
      className={styles.deromeLogo}
      data-name="Derome logotype"
      xmlns="http://www.w3.org/2000/svg"
      width="217"
      height="43.028"
      viewBox="0 0 217 43.028"
    >
      <path
        data-name="Derome logotype"
        d="M197.5,23.214c1.23-4.99,3.4-6.87,6.581-6.87,3.544,0,4.339,2.1,3.616,6.87Zm2.386,19.815a18.168,18.168,0,0,0,13.6-6.147l-3.9-4.7a10.873,10.873,0,0,1-8.389,3.471c-4.267,0-5.134-2.965-4.556-7.087h19.381l.579-3.182C218.4,15.62,214.2,9.763,205.017,9.763c-9.69,0-15.91,5.713-17.79,16.416-2.025,11.643,3.037,16.85,12.655,16.85m-67.761-.723h9.546l3.688-21.116c1.591-2.386,3.4-3.977,6.364-3.977,2.82,0,4.05,1.591,3.182,6.291l-3.327,18.73h9.546l3.76-21.84a6.622,6.622,0,0,1,5.352-3.182c3.326,0,4.7,1.3,3.616,7.593l-3.11,17.428h9.546L183.829,21.7c1.663-9.04-2.82-11.933-8.75-11.933-4.411,0-7.376,1.88-10.2,5.352-.723-3.182-3.977-5.352-8.172-5.352-5.569,0-8.606,2.531-10.992,6.075h-.362l1.013-5.352h-8.678Zm-18.8-7.015c-4.049,0-5.568-2.459-4.484-8.967,1.013-5.569,3.471-8.461,7.521-8.461s5.5,2.893,4.484,8.461c-1.23,6.509-3.471,8.967-7.521,8.967m-1.229,7.738c10.052,0,16.2-5.207,18.224-16.85,1.88-10.775-2.748-16.416-12.873-16.416-10.2,0-16.343,5.641-18.224,16.416-2.025,11.643,2.676,16.85,12.873,16.85m-38.545-.723h9.546L86.2,24.949c.651-3.616,2.893-5.207,6.219-5.207a14.967,14.967,0,0,1,4.773.723L100.448,10.2a18.776,18.776,0,0,0-4.05-.362c-3.471,0-7.449,2.459-9.546,7.232h-.434l1.374-6.509H79.115ZM52.574,23.214c1.23-4.99,3.4-6.87,6.581-6.87,3.544,0,4.339,2.1,3.616,6.87ZM54.96,43.028a18.167,18.167,0,0,0,13.6-6.147l-3.9-4.7a10.873,10.873,0,0,1-8.389,3.471c-4.267,0-5.207-2.965-4.556-7.087H71.087l.578-3.182C73.474,15.62,69.279,9.763,60.095,9.763c-9.69,0-15.909,5.713-17.79,16.416-2.1,11.643,2.965,16.85,12.655,16.85m-43.9-9.473L15.4,8.75H21.84c6.87,0,10.2,3.109,8.533,12.222C28.42,31.747,24.226,33.555,17.428,33.555ZM0,42.305H17.862c13.306,0,19.091-6.292,21.767-21.333C42.088,7.015,35.363,0,22.056,0H7.449Z"
        fill="#fff"
      />
    </svg>
  );
}

export function useLocalStorage(key: string) {
  const visitsBeforeTooltip = 10;
  const [state, setState] = useState(visitsBeforeTooltip);

  useEffect(() => {
    const localItem = localStorage.getItem(key);
    const newStateBasedOnLocalStorage =
      localItem === null || isNaN(parseInt(localItem))
        ? visitsBeforeTooltip
        : parseInt(localItem) - 1;

    localStorage.setItem(key, String(newStateBasedOnLocalStorage));
    setState(newStateBasedOnLocalStorage);
  }, [key]);

  const setWithLocalStorage = (nextState: number) => {
    localStorage.setItem(key, String(nextState));
    setState(nextState);
  };

  return [state, setWithLocalStorage] as const;
}
