import styles from './styles.module.css';

interface Props {
  active?: boolean;
}

export const Loader = ({ active }: Props) =>
  active ? (
    <div>
      <div className={styles.loader}>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  ) : (
    <></>
  );
