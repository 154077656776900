import { useQuery } from 'react-query';
import request from 'graphql-request';

import { GetShipmentSummaryLegacyDocument } from '../gql/graphql';
import { ACCESS_KEY, ENGINE_API } from './shared';

export const getShipmentSummaryLegacy = (orderNo: string, enabled: boolean) => {
  return useQuery(
    ['shipment-summary-legacy', orderNo],
    async () =>
      request({
        url: ENGINE_API,
        document: GetShipmentSummaryLegacyDocument,
        variables: { orderNo },
        requestHeaders: { 'x-api-key': ACCESS_KEY },
      }),
    {
      enabled,
    },
  );
};
