export var Category;
(function (Category) {
    Category["DamagedGoods"] = "skadad_vara";
    Category["MissingGoods"] = "utebliven_vara";
    Category["WrongGoods"] = "fel_vara";
    Category["DelayedDelivery"] = "f\u00F6rsenad_leverans";
    Category["EarlyDelivery"] = "f\u00F6r_tidig_leverans";
    Category["Service"] = "service";
    Category["WrongCrane"] = "fel_kran";
    Category["Other"] = "\u00F6vrigt";
    Category["None"] = "";
})(Category || (Category = {}));
/**
 * Transforms given string to a category enum representation
 */
export const CategoryFromString = (s) => {
    const d = s;
    switch (d) {
        case 'skadad_vara':
            return Category.DamagedGoods;
        case 'utebliven_vara':
            return Category.MissingGoods;
        case 'fel_vara':
            return Category.WrongGoods;
        case 'försenad_leverans':
            return Category.DelayedDelivery;
        case 'för_tidig_leverans':
            return Category.EarlyDelivery;
        case 'service':
            return Category.Service;
        case 'fel_kran':
            return Category.WrongCrane;
        case 'övrigt':
            return Category.Other;
        default:
            return Category.None;
    }
};
const categories = [
    'skadad_vara',
    'utebliven_vara',
    'fel_vara',
    'försenad_leverans',
    'för_tidig_leverans',
    'service',
    'fel_kran',
    'övrigt',
];
