import styles from './highligh.module.css';

interface Props {
  onClick?: () => void;
}

export const Highlight = (p: Props) => {
  return (
    <div data-testid="overlay" className={styles.overlay} onClick={p.onClick} />
  );
};
