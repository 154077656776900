import { useQuery } from 'react-query';
import request from 'graphql-request';

import { GetShipmentSummaryDocument } from '../gql/graphql';
import { ACCESS_KEY, ENGINE_API } from './shared';

export const getShipmentSummary = (hash: string, enabled: boolean) => {
  return useQuery(
    ['shipment-summary', hash],
    async () =>
      request({
        url: ENGINE_API,
        document: GetShipmentSummaryDocument,
        variables: { hash },
        requestHeaders: { 'x-api-key': ACCESS_KEY },
      }),
    {
      enabled,
    },
  );
};
