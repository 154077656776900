import { useQuery } from 'react-query';
import request from 'graphql-request';

import { GetOrderTrackerDocument } from '../gql/graphql';
import { ACCESS_KEY, ENGINE_API } from './shared';

export const getOrderTracker = (orderNo: string, enabled: boolean) => {
  return useQuery(
    ['order-tracker', orderNo],
    async () =>
      request({
        url: ENGINE_API,
        document: GetOrderTrackerDocument,
        variables: {
          orderNo: orderNo,
        },
        requestHeaders: {
          'x-api-key': ACCESS_KEY,
        },
      }),
    {
      enabled,
    },
  );
};
