export var EventType;
(function (EventType) {
    EventType["CustomerOrderStatus"] = "customerorderstatus";
    EventType["OrderTrackerUpdate"] = "ordertrackerupdate";
    EventType["TransportOrderStatus"] = "transportorderstatus";
    EventType["ConsolidationStatus"] = "consolidationstatus";
    EventType["Order"] = "order";
    EventType["OrderStream"] = "orderstream";
    EventType["Shipment"] = "shipment";
    EventType["ShipmentStream"] = "shipmentstream";
    EventType["Consolidation"] = "consolidation";
    EventType["ConsolidationStream"] = "consolidationstream";
    EventType["Notification"] = "notification";
    EventType["NotificationStream"] = "notificationstream";
    EventType["SmsNotification"] = "sms-notification";
    EventType["SmsStatusStream"] = "sms-statusstream";
    EventType["EmailNotification"] = "email-notification";
    EventType["TriggerNotification"] = "trigger-notification";
    EventType["CustomerFeedbackStream"] = "customer-feedback-stream";
    EventType["NotificationOpened"] = "notification-opened";
    EventType["PartInfo"] = "partinfo";
    EventType["TransportInfo"] = "transportinfo";
    EventType["TransportInfoStream"] = "transportinfo-stream";
    EventType["FleetplannerDeliveryProofUpload"] = "fleetplanner-delivery-proof-upload";
    EventType["CustomerInvoice"] = "customerinvoice";
    EventType["CustomerInvoiceDocument"] = "customerinvoicedocument";
    EventType["UserRelationChange"] = "user-relation-change";
})(EventType || (EventType = {}));
export var EventSource;
(function (EventSource) {
    EventSource["ProcessObject"] = "process-object";
    EventSource["OrderTracker"] = "order-tracker";
    EventSource["OrderNotification"] = "order-notification";
    EventSource["OrderStream"] = "order-stream";
    EventSource["ConsolidationNotification"] = "consolidation-notification";
    EventSource["ConsolidationStream"] = "consolidation-stream";
    EventSource["ShipmentNotification"] = "shipment-notification";
    EventSource["ShipmentStream"] = "shipment-stream";
    EventSource["NotificationStream"] = "notification-stream";
    EventSource["SmsEmailNotification"] = "sms-email-notification";
    EventSource["SmsNotification"] = "sms-notification";
    EventSource["SmsStatus"] = "sms-status";
    EventSource["SmsStatusStream"] = "sms-status-stream";
    EventSource["EmailNotification"] = "sms-notification";
    EventSource["CustomerFeedbackStream"] = "customer-feedback-stream";
    EventSource["TrackApi"] = "track-api";
    EventSource["TransportInfoStream"] = "transportinfo-stream";
    EventSource["AddProjectFunction"] = "add-project-function";
    EventSource["Fleetplanner"] = "fleetplanner";
    EventSource["UserRelation"] = "user-relation";
})(EventSource || (EventSource = {}));
