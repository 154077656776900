/**
 * The type of the invoice.
 */
export var InvoiceType;
(function (InvoiceType) {
    /**
     * Advance Customer Order Credit Invoice
     */
    InvoiceType[InvoiceType["COADVCRE"] = 0] = "COADVCRE";
    /**
     * Advance Customer Order Debit Invoice
     */
    InvoiceType[InvoiceType["COADVDEB"] = 1] = "COADVDEB";
    /**
     * Rebate Invoice
     */
    InvoiceType[InvoiceType["COREBCRE"] = 2] = "COREBCRE";
    /**
     * Correction Instant Customer Invoice
     */
    InvoiceType[InvoiceType["CORRINV"] = 3] = "CORRINV";
    /**
     * Customer Order Collective Correction Invoice
     */
    InvoiceType[InvoiceType["CUSTCOLCOR"] = 4] = "CUSTCOLCOR";
    /**
     * Customer Order Collective Invoice, Credit
     */
    InvoiceType[InvoiceType["CUSTCOLCRE"] = 5] = "CUSTCOLCRE";
    /**
     * Customer Order Collective Invoice, Debit
     */
    InvoiceType[InvoiceType["CUSTCOLDEB"] = 6] = "CUSTCOLDEB";
    /**
     * Customer Order Invoice, Credit
     */
    InvoiceType[InvoiceType["CUSTORDCRE"] = 7] = "CUSTORDCRE";
    /**
     * Customer Order Invoice, Debit
     */
    InvoiceType[InvoiceType["CUSTORDDEB"] = 8] = "CUSTORDDEB";
    /**
     * Instant Customer Invoice
     */
    InvoiceType[InvoiceType["INSTINV"] = 9] = "INSTINV";
    /**
     * Recurring Instant Invoice
     */
    InvoiceType[InvoiceType["INSTRECINV"] = 10] = "INSTRECINV";
    /**
     * Interest Customer Invoice
     */
    InvoiceType[InvoiceType["INTRINV"] = 11] = "INTRINV";
})(InvoiceType || (InvoiceType = {}));
/**
 * The series ID of the invoice. Alt key
 */
export var SeriesID;
(function (SeriesID) {
    SeriesID["Ad"] = "AD";
    SeriesID["CD"] = "CD";
})(SeriesID || (SeriesID = {}));
/**
 * The current state or status of the invoice.
 */
export var State;
(function (State) {
    State["PaidPosted"] = "PaidPosted";
    State["PartlyPaidPosted"] = "PartlyPaidPosted";
    State["PostedAuth"] = "PostedAuth";
})(State || (State = {}));
