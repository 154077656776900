import dayjs from 'dayjs';
export const roundToNearestHour = (date) => {
    date.setMinutes(date.getMinutes() + 30);
    date.setMinutes(0, 0, 0);
    return date;
};
export const formatDateWithTimeSpan = (dateString, offsetStart, offsetEnd, spanType = 'hours', now = new Date()) => {
    const expectedDelivery = dayjs(dateString);
    const adjustedStart = adjustDatetimeToClosest5min(expectedDelivery);
    const start = adjustedStart.subtract(offsetStart, spanType);
    const end = adjustedStart.add(offsetEnd, spanType);
    return formatDateString(start.toDate(), end.toDate(), now);
};
/**
 * Check bounds non-timestamp deliveries must be inside 0630 & 1700.
 * If a valid format moves outside of these bounds we need to "cut" them off.
 */
export const formatDateWithTimeSpanForNonTimestampDelivery = (dateString, offsetStart, offsetEnd, spanType = 'hours', now = new Date()) => {
    const origin = adjustDatetimeToClosest5min(dayjs(dateString));
    let start = origin.subtract(offsetStart, spanType);
    let end = origin.add(offsetEnd, spanType);
    const lowerBound = origin.set('h', 6).set('m', 30);
    const upperBound = origin.set('h', 17).set('m', 0);
    if (lowerBound > start) {
        start = lowerBound;
    }
    if (upperBound < end) {
        end = upperBound;
    }
    return formatDateString(start.toDate(), end.toDate(), now);
};
/**
 * Adjust given datetime to closest 5min (or 00)
 */
const adjustDatetimeToClosest5min = (date) => {
    const diff = date.minute() % 5;
    return diff === 0 ? date : date.add(5 - diff, 'minutes');
};
export const formatDateString = (startDate, endDate, now) => {
    const start = dayjs(startDate);
    const end = dayjs(endDate);
    const date = isToday(now, start.toDate())
        ? 'idag'
        : start.format('YYYY-MM-DD');
    return `${date} kl ${start.format('HH:mm')} - ${end.format('HH:mm')}`;
};
export const formatDate = (dateString, today = new Date()) => {
    const date = dayjs(dateString);
    return isToday(date.toDate(), today) ? 'idag' : date.format('YYYY-MM-DD');
};
export const formatDateWithHoursTimeSpan = (dateString, today = new Date()) => {
    const date = new Date(dateString);
    const datePart = isToday(date, today) ? 'idag' : formatDate(dateString);
    const startDate = roundToNearestHour(date);
    const endDate = new Date(startDate.getTime());
    endDate.setMinutes(endDate.getMinutes() + 60 * 4);
    const start = ('0' + `${startDate.getHours()}`).slice(-2);
    const end = ('0' + `${endDate.getHours()}`).slice(-2);
    return `${datePart} kl ${start}-${end}`;
};
export const isToday = (someDate, today = new Date()) => {
    return (someDate.getDate() === today.getDate() &&
        someDate.getMonth() === today.getMonth() &&
        someDate.getFullYear() === today.getFullYear());
};
export const isTodayStr = (someDate, today = new Date()) => isToday(new Date(someDate), today);
export const getHash = (value) => {
    return value
        .split('')
        .reduce((prevHash, currVal) => ((prevHash << 5) - prevHash + currVal.charCodeAt(0)) | 0, 0);
};
export const wait = (seconds) => {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve();
        }, seconds * 1000);
    });
};
export var Runtime;
(function (Runtime) {
    Runtime["Dev"] = "dev";
    Runtime["Prod"] = "prod";
    Runtime["Test"] = "test";
})(Runtime || (Runtime = {}));
export const runtimeFromStr = (str) => {
    switch (str) {
        case Runtime.Dev:
            return Runtime.Dev;
        case Runtime.Prod:
            return Runtime.Prod;
        case Runtime.Test:
            return Runtime.Test;
        default:
            throw new Error(`${str} is not supported as an env`);
    }
};
export const createIsoTimestamp = () => {
    return dayjs().toISOString();
};
