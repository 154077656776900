export var OrderStatus;
(function (OrderStatus) {
    OrderStatus["RESERVED"] = "RESERVED";
    OrderStatus["PRELIMINARY"] = "PRELIMINARY";
    OrderStatus["CONFIRMED"] = "CONFIRMED";
    OrderStatus["CONFIRMED_PICKUP"] = "CONFIRMED_PICKUP";
    OrderStatus["CANCELLED"] = "CANCELLED";
    OrderStatus["READY_FOR_PICKUP"] = "READY_FOR_PICKUP";
    OrderStatus["DELIVERED"] = "DELIVERED";
    OrderStatus["DELIVERED_PICKUP"] = "DELIVERED_PICKUP";
    OrderStatus["UPDATE"] = "UPDATE";
    OrderStatus["INVOICED"] = "INVOICED";
    OrderStatus["UNDETERMINED"] = "UNDETERMINED";
})(OrderStatus || (OrderStatus = {}));
export var SupplyCodes;
(function (SupplyCodes) {
    SupplyCodes["IntPurchTrans"] = "IPT";
    SupplyCodes["PurchOrderDir"] = "PD";
    SupplyCodes["InventOrder"] = "IO";
    SupplyCodes["PurchOrderTrans"] = "PT";
    SupplyCodes["DOPOrder"] = "DOP";
    SupplyCodes["IntPurchDir"] = "IPD";
    SupplyCodes["ShopOrder"] = "SO";
    SupplyCodes["Pkg"] = "PKG";
    SupplyCodes["NotDecided"] = "ND";
    SupplyCodes["NonInventory"] = "NO";
})(SupplyCodes || (SupplyCodes = {}));
export var DemandCodes;
(function (DemandCodes) {
    DemandCodes["IntPurchTrans"] = "IPT";
    DemandCodes["IntPurchDir"] = "IPD";
    DemandCodes["SalesQuotation"] = "SQ";
    DemandCodes["DistributionOrder"] = "DO";
    DemandCodes["ProjectInventory"] = "PI";
})(DemandCodes || (DemandCodes = {}));
export var OrderLineState;
(function (OrderLineState) {
    OrderLineState["CANCELLED"] = "Cancelled";
    OrderLineState["DELIVERED"] = "Delivered";
    OrderLineState["INVOICED"] = "Invoiced";
    OrderLineState["PARTIALLY_DELIVERED"] = "PartiallyDelivered";
    OrderLineState["PICKED"] = "Picked";
    OrderLineState["RELEASED"] = "Released";
    OrderLineState["RESERVED"] = "Reserved";
})(OrderLineState || (OrderLineState = {}));
export var PurchaseOrderState;
(function (PurchaseOrderState) {
    PurchaseOrderState["Undetermined"] = "Undetermined";
    PurchaseOrderState["Released"] = "Released";
    PurchaseOrderState["Cancelled"] = "Cancelled";
    PurchaseOrderState["Confirmed"] = "Confirmed";
    PurchaseOrderState["Arrived"] = "Arrived";
    PurchaseOrderState["Closed"] = "Closed";
    PurchaseOrderState["Received"] = "Received";
})(PurchaseOrderState || (PurchaseOrderState = {}));
export var ShipViaCodes;
(function (ShipViaCodes) {
    ShipViaCodes["DIR"] = "DIR";
    ShipViaCodes["T15"] = "T15";
    ShipViaCodes["T25"] = "T25";
    ShipViaCodes["T55"] = "T55";
    ShipViaCodes["EX1"] = "EX1";
    ShipViaCodes["EX2"] = "EX2";
    ShipViaCodes["BIO"] = "BIO";
    ShipViaCodes["DJV"] = "DJV";
    ShipViaCodes["MOT"] = "MOT";
    ShipViaCodes["LJV"] = "LJV";
    ShipViaCodes["TJO"] = "TJO";
    ShipViaCodes["HDT"] = "HDT";
    ShipViaCodes["BOS"] = "BOS";
    ShipViaCodes["AVR"] = "AVR";
    ShipViaCodes["BIL"] = "BIL";
    ShipViaCodes["BKM"] = "BKM";
    ShipViaCodes["BUD"] = "BUD";
    ShipViaCodes["BUT"] = "BUT";
    ShipViaCodes["B\u00C5T"] = "B\u00C5T";
    ShipViaCodes["TRU"] = "TRU";
    ShipViaCodes["TUR"] = "TUR";
    ShipViaCodes["FJ\u00C4"] = "FJ\u00C4";
    ShipViaCodes["HMT"] = "HMT";
    ShipViaCodes["HTI"] = "HTI";
    ShipViaCodes["HUS"] = "HUS";
    ShipViaCodes["JRN"] = "JRN";
    ShipViaCodes["K29"] = "K29";
    ShipViaCodes["KRA"] = "KRA";
    ShipViaCodes["KRM"] = "KRM";
    ShipViaCodes["KRP"] = "KRP";
    ShipViaCodes["LST"] = "LST";
    ShipViaCodes["PIC"] = "PIC";
    ShipViaCodes["SK\u00C5"] = "SK\u00C5";
    ShipViaCodes["SL\u00C4"] = "SL\u00C4";
    ShipViaCodes["STY"] = "STY";
    ShipViaCodes["T10"] = "T10";
    ShipViaCodes["T11"] = "T11";
    ShipViaCodes["T12"] = "T12";
    ShipViaCodes["T13"] = "T13";
    ShipViaCodes["T14"] = "T14";
    ShipViaCodes["T20"] = "T20";
    ShipViaCodes["T21"] = "T21";
    ShipViaCodes["T22"] = "T22";
    ShipViaCodes["T23"] = "T23";
    ShipViaCodes["T24"] = "T24";
    ShipViaCodes["T30"] = "T30";
    ShipViaCodes["T31"] = "T31";
    ShipViaCodes["T32"] = "T32";
    ShipViaCodes["T34"] = "T34";
    ShipViaCodes["T40"] = "T40";
    ShipViaCodes["T41"] = "T41";
    ShipViaCodes["T50"] = "T50";
})(ShipViaCodes || (ShipViaCodes = {}));
export var DeliveryTerms;
(function (DeliveryTerms) {
    DeliveryTerms["OE\u00D6"] = "OE\u00D6";
    DeliveryTerms["BUT"] = "BUT";
    DeliveryTerms["TJO"] = "TJO";
    DeliveryTerms["CFR"] = "CFR";
    DeliveryTerms["CIF"] = "CIF";
    DeliveryTerms["CIP"] = "CIP";
    DeliveryTerms["CPT"] = "CPT";
    DeliveryTerms["DAP"] = "DAP";
    DeliveryTerms["DDP"] = "DDP";
    DeliveryTerms["DPU"] = "DPU";
    DeliveryTerms["EXW"] = "EXW";
    DeliveryTerms["FAS"] = "FAS";
    DeliveryTerms["FCA"] = "FCA";
    DeliveryTerms["FOB"] = "FOB";
    DeliveryTerms["HLS"] = "HLS";
    DeliveryTerms["HOS"] = "HOS";
    DeliveryTerms["LIK"] = "LIK";
    DeliveryTerms["LLK"] = "LLK";
    DeliveryTerms["LOK"] = "LOK";
    DeliveryTerms["MOT"] = "MOT";
})(DeliveryTerms || (DeliveryTerms = {}));
