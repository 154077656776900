import { ShipmentTracker } from '@deromeab/components';

import styles from './style.module.css';
import { CustomerInfo } from './components/CustomerInfo';
import { NotificationSettings } from './components/NotificationSettings';
import { PageHeader } from '../../components/Headers/Headers';
import { ShipmentSummary } from '../../gql/graphql';
import { Tracker } from './data/Tracker';
import { setFeedback } from '../../queries/set-feedback';
import { useState } from 'react';

interface OrderTrackerProps {
  orderNo: string;
  marking: string;
  customerId: string;
  registered: string;
  shipmentSummary: ShipmentSummary;
}

export const OrderTracker = ({
  orderNo,
  customerId,
  shipmentSummary,
  registered,
}: OrderTrackerProps) => {
  const { shipments, unPlannedLines, phone, marking } = shipmentSummary;
  const firstShipment = shipments?.find((s) => s.pickup);
  const [isSubmitted, setIsSubmitted] = useState(false);

  return (
    <div className={styles.componentContentFlex}>
      <PageHeader header="Följ din order" />

      <div className={styles.comboHeaderContainer}>
        <NotificationSettings customerId={phone || customerId} />
      </div>

      <div className={styles.trackerSpacer}>
        <CustomerInfo
          orderNo={orderNo}
          marking={marking}
          // @ts-ignore
          pickup={firstShipment?.pickup}
          shipViaCode={firstShipment?.shipViaCode}
          shipmentId={firstShipment?.id}
        />
      </div>

      {shipments?.map((shipment, i) => {
        const variantTitle = !!shipment.pickup ? 'Hämtorder' : 'Utkörning';

        return (
          <div key={i} className={styles.trackerSpacer}>
            <ShipmentTracker
              title={
                shipments.length > 1
                  ? `Delleverans ${i + 1}/${shipments.length}: ${variantTitle}`
                  : variantTitle
              }
              // TODO: fix type err from Maybe variant to nullable
              // @ts-ignore
              shipment={shipment}
              isSubmited={isSubmitted}
              onFeedbackSubmit={async (score, text) => {
                await setFeedback({
                  orderNo,
                  ref: shipment.id,
                  text: text ?? '',
                  score: score,
                }).then(() => {
                  setIsSubmitted(true);
                });
              }}
              defaultDisplay={i == 0}
            />
          </div>
        );
      })}

      {unPlannedLines?.length > 0 && (
        <ShipmentTracker
          title={'Väntar på att planeras för leverans'}
          shipment={{
            id: '',
            images: [],
            delivery: {
              registeredDatetime: registered,
              company: '',
              routeId: '',
            },
            // TODO: fix type err from Maybe variant to nullable
            // @ts-ignore
            lines: unPlannedLines,
          }}
          onFeedbackSubmit={(_score, _text) => { }}
          defaultDisplay={shipments.length == 0}
        />
      )}
    </div>
  );
};

export const isDelivered = (tracker: Tracker) => {
  // Check status of latest order event
  const state = tracker.order?.state?.toLowerCase();
  const isDelivered =
    state == 'delivered' || state == 'delivered_pickup' || state == 'invoiced';

  // Check if there is at least 1 completed trip
  const hasCompletedTrip = !!tracker.trips.find((trip) => !!trip.completed);

  return isDelivered || hasCompletedTrip;
};

export const isPickupOrder = (shipViaCode: string) =>
  ['HMT', 'HTI', 'HDT', 'CNC'].includes(shipViaCode ?? '');
