import request from 'graphql-request';

import { SetCustomerContactSettingDocument } from '../gql/graphql';
import { ACCESS_KEY, ENGINE_API } from './shared';

export const setCustomerContactSetting = async (opt: {
  customerId: string;
  setting: number;
}) => {
  return await request({
    url: ENGINE_API,
    document: SetCustomerContactSettingDocument,
    variables: {
      customerId: opt.customerId,
      setting: opt.setting,
    },
    requestHeaders: {
      'x-api-key': ACCESS_KEY,
    },
  });
};
