import { useQuery } from 'react-query';
import request from 'graphql-request';

import { GetCustomerContactSettingDocument } from '../gql/graphql';
import { ACCESS_KEY, ENGINE_API } from './shared';

export const getCustomerContactSetting = (customerId?: string) => {
  return useQuery({
    queryKey: 'customer-contact-setting',
    queryFn: () => {
      if (!customerId) return;

      return request({
        url: ENGINE_API,
        document: GetCustomerContactSettingDocument,
        variables: {
          customerId: customerId!,
        },
        requestHeaders: {
          'x-api-key': ACCESS_KEY,
        },
      });
    },
  });
};
