export var CustomerContactType;
(function (CustomerContactType) {
    /**
     * Ordered By
     */
    CustomerContactType["CUST_REF"] = "CUST_REF";
    /**
     * Order receiver
     */
    CustomerContactType["RECEIVER"] = "RECEIVER";
    CustomerContactType["OTHER"] = "OTHER";
    CustomerContactType["PROJECT"] = "PROJECT";
})(CustomerContactType || (CustomerContactType = {}));
export var ContactNotificationType;
(function (ContactNotificationType) {
    ContactNotificationType["Email"] = "EMAIL";
    ContactNotificationType["Sms"] = "SMS";
    ContactNotificationType["SmsAndEmail"] = "SMS_AND_EMAIL";
})(ContactNotificationType || (ContactNotificationType = {}));
export var DeliveryInfoLevel;
(function (DeliveryInfoLevel) {
    DeliveryInfoLevel["None"] = "NONE";
    DeliveryInfoLevel["Min"] = "MIN";
    DeliveryInfoLevel["Avg"] = "AVG";
    DeliveryInfoLevel["Max"] = "MAX";
})(DeliveryInfoLevel || (DeliveryInfoLevel = {}));
export var NotificationFlags;
(function (NotificationFlags) {
    NotificationFlags[NotificationFlags["None"] = 0] = "None";
    NotificationFlags[NotificationFlags["OrderConfirmation"] = 1] = "OrderConfirmation";
    NotificationFlags[NotificationFlags["DeliveryPlanned"] = 2] = "DeliveryPlanned";
    NotificationFlags[NotificationFlags["DriveTo"] = 4] = "DriveTo";
    NotificationFlags[NotificationFlags["Completed"] = 8] = "Completed";
    NotificationFlags[NotificationFlags["ReayForPickup"] = 16] = "ReayForPickup";
})(NotificationFlags || (NotificationFlags = {}));
export const AllNotificationFlags = NotificationFlags.OrderConfirmation |
    NotificationFlags.DeliveryPlanned |
    NotificationFlags.DriveTo |
    NotificationFlags.Completed |
    NotificationFlags.ReayForPickup;
export const wantsOrderConfirmation = (contact) => {
    return ((contact.notificationSettings & NotificationFlags.OrderConfirmation) !== 0);
};
export const wantsDeliveryPlanned = (contact) => {
    return ((contact.notificationSettings & NotificationFlags.DeliveryPlanned) !== 0);
};
export const wantsDriveTo = (contact) => {
    return (contact.notificationSettings & NotificationFlags.DriveTo) !== 0;
};
export const wantsDelivered = (contact) => {
    return (contact.notificationSettings & NotificationFlags.Completed) !== 0;
};
