import '@deromeab/components/dist/esm/index.css';
import styles from './styles.module.css';

import { useEffect } from 'react';
import { Spinner } from '@deromeab/components';

export const PageSpinner = () => {
  useEffect(() => {
    // Prevent scrolling on mount
    document.body.style.overflow = "hidden";

    // Re-enable scrolling when component unmounts
    return () => {
      document.body.style.overflow = "visible";
    };
  }, []);

  return (
    <div className={styles.pageSpinnerWrapper}>
      <Spinner size='xlarge'/>
    </div>
  )
}
